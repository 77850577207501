<template>
    <b-card title="Choose Viewer">
        <div class="mt-3">
            <div class="activity-content">
                <p class="title-width">Citizenship:</p> 
                <div v-if="rows.citizenship.names.length > 0">
                    <p v-for="(name, index) in rows.citizenship.names" :key="index">{{ name }}</p>
                </div>
                <div v-else>
                    N / A
                </div>
            </div>
            <div class="activity-content mt-2">
                <p class="title-width">Countries:</p> 
                <div v-if="rows.country.names.length > 0">
                    <p v-for="(name, index) in rows.country.names" :key="index">{{ name }}</p>
                </div>
                <div v-else>
                    N / A
                </div>
            </div>
            <div class="activity-content mt-2">
                <p class="title-width">Districts:</p> 
                <div v-if="rows.district.names.length > 0">
                    <p v-for="(name, index) in rows.district.names" :key="index">{{ name }}</p>
                </div>
                <div v-else>
                    N / A
                </div>
            </div>
            <div class="activity-content mt-2">
                <p class="title-width">Hometown:</p> 
                <div v-if="rows.hometown.names.length > 0">
                    <p v-for="(name, index) in rows.hometown.names" :key="index">{{ name }}</p>
                </div>
                <div v-else>
                    N / A
                </div>
            </div>
            <div class="activity-content mt-2">
                <p class="title-width">Area:</p> 
                <div v-if="rows.area.names.length > 0">
                    <p v-for="(name, index) in rows.area.names" :key="index">{{ name }}</p>
                </div>
                <div v-else>
                    N / A
                </div>
            </div>
            <div class="activity-content mt-2">
                <p class="title-width">Education:</p> 
                <div v-if="rows.education.names.length > 0">
                    <p v-for="(name, index) in rows.education.names" :key="index">{{ name }}</p>
                </div>
                <div v-else>
                    N / A
                </div>
            </div>
            <div class="activity-content mt-2">
                <p class="title-width">Profession:</p> 
                <div v-if="rows.profession.names.length > 0">
                    <p v-for="(name, index) in rows.profession.names" :key="index">{{ name }}</p>
                </div>
                <div v-else>
                    N / A
                </div>
            </div>
            <div class="activity-content mt-2">
                <p class="title-width">Profession:</p> 
                <div v-if="rows.profession.names.length > 0">
                    <p v-for="(name, index) in rows.profession.names" :key="index">{{ name }}</p>
                </div>
                <div v-else>
                    N / A
                </div>
            </div>
            <div class="activity-content mt-2">
                <p class="title-width">Marital Status:</p> 
                <div v-if="rows.marital_status.length > 0">
                    <p v-for="(name, index) in rows.marital_status" :key="index">{{ name }}</p>
                </div>
                <div v-else>
                    N / A
                </div>
            </div>
            <div class="activity-content mt-2">
                <p class="title-width">Religion:</p> 
                <div v-if="rows.religion.length > 0">
                    <p v-for="(name, index) in rows.religion" :key="index">{{ name }}</p>
                </div>
                <div v-else>
                    N / A
                </div>
            </div>
            <div class="activity-content mt-2">
                <p class="title-width">Min Age:</p> 
                <div v-if="rows.min_age != null">
                    <p>{{ rows.min_age }}</p>
                </div>
                <div v-else>
                    N / A
                </div>
            </div>
            <div class="activity-content mt-2">
                <p class="title-width">Max Age:</p> 
                <div v-if="rows.max_age != null">
                    <p>{{ rows.max_age }}</p>
                </div>
                <div v-else>
                    N / A
                </div>
            </div>
        </div>
    </b-card>
</template>
  
  <script>
  
import {
BAvatar,BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard,
BModal,VBModal,BCardText, BFormTextarea
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BCard,
        BBadge,
        BButton,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BModal,
        vSelect,
        BCardText,
        BFormTextarea
    },

    directives: {
        'b-modal': VBModal,
        Ripple,
    },

    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: '#',
                    field: 'index',
                    type:'number',
                    tdClass: 'text-center ',
                    thClass:'text-center'
                },
                {
                    label: 'Activity',
                    field: 'activity',
                    tdClass: 'text-center',
                    thClass:'text-center'
                }  
            ],
            rows: [],
            searchTerm: '',
        }
    },

    computed: {
        ...mapState('users', ["parameters","userError","userLoading"]),
    
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },

    watch: {
        parameters(newValue, oldValue) {
            this.rows = this.parameters
        }
    },

    created() {
        this.$store.dispatch('users/fetchSavedParameters', this.$route.params.id)
        this.rows = this.parameters
        let permissions=JSON.parse(localStorage.getItem('permissions'))
        //   this.showAdd=permissions.includes("add_area")
        //   this.showEdit=permissions.includes("change_area")
        //   this.showDelete=permissions.includes("delete_area")
    },

    methods: {
        getActivationDate(time) {
            return moment(time).format('MMMM Do YYYY, h:mm:ss a')
        }
    }
}
</script>
  
<style lang="scss" scoped>
.no-outline{
    border:none !important;
}

.f20{
    font-size: 8px !important;
}

.vs__dropdown-menu {
  height: 200px;
}

.activity-content {
    font-size: medium;
    display: flex;
    gap: 1rem;
}

.title-width {
    width: 250px;
    font-weight: 600;
}

@media screen and (max-width: 600px) {
    .activity-content {
        font-size: medium;
        display: flex;
        flex-direction: column;
        gap: 2px;
    }
}

@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>